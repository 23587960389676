import React from "react";
import './ContactForm.css';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const ContactForm = () => {
    return (
        <div>
            <br />
            <br />

            <div className="container">
                <h4 className="relationHeadCF">Get in touch today to discover what we can do for you</h4>
                {/* <p className="relationParaCF">Beyond our skilled and expertise, it is our customers 
                and our relationship with them that sets us apart.
                </p> */}
                <br />
                <br />


                <div className="form">
                    <input type="text" className="item1" placeholder="Your Name" />
                    <input type="email" className="item2" placeholder="Your Email" />
                    <br />
                    <br />
                    <br />
                    {/* <br/> */}
                    <input type="phone" className="item1" placeholder="Your Phone (Optional)" />
                    <input ty r43212345pe="text" className="item2" placeholder="Your Company/Organization (Optional)" />
                    <br />
                    <br />
                    <br />

                <Row>
                    <Col >
                        <div className="_field-wrapper">
                            <select name="field[11]" id="field[11]" required="" data-name="which_services_are_you_interested_in">
                                <option selected="">Which Service Are You Interested In?
                                </option>
                                <option value="Enterprise Software Solutions">
                                    Enterprise Software Solutions
                                </option>
                                <option value="Dedicated Development Team">
                                    Dedicated Development Team
                                </option>
                                <option value="Mobile App Development">
                                    Mobile App Development
                                </option>
                                <option value="Web App Development">
                                    Web App Development
                                </option>
                                <option value="Data Services">
                                    Data Services
                                </option>
                                <option value="Open edX Services">
                                    Open edX Services
                                </option>
                                <option value="UI/UX">
                                    UI/UX
                                </option>
                                <option value="DevOps">
                                    DevOps
                                </option>
                                <option value="3D App Development">
                                    3D App Development
                                </option>
                                <option value="Quality Assurance">
                                    Quality Assurance
                                </option>
                                <option value="Partnership Opportunities">
                                    Partnership Opportunities
                                </option>
                                <option value="RFQ/RFP">
                                    RFQ/RFP
                                </option>
                            </select>
                        </div>
                    </Col>

                    <Col>
                        <div className="_field-wrapper1">
                            <select name="field[11]" id="field[11]" required="" data-name="which_services_are_you_interested_in">
                                <option selected="">Select your country
                                </option>
                                <option value="Enterprise Software Solutions">
                                    Afghanistan
                                </option>
                                <option value="Dedicated Development Team">
                                    Pakistan
                                </option>
                                <option value="Mobile App Development">
                                    Algeria
                                </option>
                                <option value="Web App Development">
                                    Andorra
                                </option>
                                <option value="Data Services">
                                    Brazil
                                </option>
                                <option value="Open edX Services">
                                    China
                                </option>
                                <option value="UI/UX">
                                    Denmark
                                </option>
                                <option value="DevOps">
                                    Egypt
                                </option>
                                <option value="3D App Development">
                                    Finland
                                </option>
                                <option value="Quality Assurance">
                                    Greece
                                </option>
                                <option value="Partnership Opportunities">
                                    Hungary
                                </option>
                                <option value="RFQ/RFP">
                                    Jordan
                                </option>
                            </select>

                        </div>


                    </Col>
                    </Row>

                    <br />
                    <br />


                    <div class="_field-wrapper">
                        <textarea id="field" name="field[6]" placeholder="Let us know a bit more about the project you have in mind..." required="" data-name="message"></textarea>
                    </div>

                    <br />

                    {/* <div style={{ display: 'flex' }}>
                        <input type="checkbox" className="check" />
                        <p>Please send me a non-disclosure agreement</p>

                    </div> */}

                    <br />

                    <div class="_button-wrapper _full_width">
                        <button id="_form_255_submit" class="_submit" type="submit">
                            LET'S START A CONVERSATION
                        </button>
                    </div>

                </div>

            </div>


        </div>
    );
}