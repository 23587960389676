import React from "react";
import './About.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export const About = () => {
    return (
        <div id="aboutId">
            <br/>
            <br/>
            <Container>
                <Row>
                    <Col>
                        <h2 className="headerSliderAbout">
                            FEW WORDS ABOUT US
                        </h2>
                        {/* <br/> */}
                        <p className="container" id="textSliderAbout" style={{}}>
                            Silverback is a premiere Internet of Things centric technology
                            company aimed at providing cutting edge innovative solutions.
                            Silverback, established in 2015, provides tools for creators to
                            develop internet-connected hardware. Our goal is to provide the
                            best place for learning electronics and making the best products
                            for makers of all ages and skill levels. Company expertise includes
                            the integration of ARM cortex controllers with connectivity modules
                            and web application development. Silverback team has a wide experience
                            in the core engineering skills that are required to develop and test
                            connected devices and IoT products.


                        </p>
                    </Col>

                </Row>
            </Container>
            {/* <div className="container">
                <h2 className="headerSliderAbout">
                    FEW WORDS ABOUT US
                </h2>
                {/* <br/> */}
                {/* <p className="container" id="textSliderAbout" style={{}}>
                    Silverback is a premiere Internet of Things centric technology
                    company aimed at providing cutting edge innovative solutions.
                    Silverback, established in 2015, provides tools for creators to
                    develop internet-connected hardware. Our goal is to provide the
                    best place for learning electronics and making the best products
                    for makers of all ages and skill levels. Company expertise includes
                    the integration of ARM cortex controllers with connectivity modules
                    and web application development. Silverback team has a wide experience
                    in the core engineering skills that are required to develop and test
                    connected devices and IoT products.


                </p>
            </div> */} 

        </div>
    );
}