import React from "react";
import './Product.css';
// import { Container,Row,Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import surilli from '../assets/surrili9.png';
import vap from '../assets/vap9.png';
// import web3 from '../assets/data.svg';    height: 280px;




export const Products = () => {
    return (
        <div id="productsId">
            {/* PRODUCTS tttt */}
            <br />
            <br />
            <h4 className="relationHeadProducts">
                {/* Our clients are doing amazing work with the software we built */}
                OUR CORE PRODUCTS
            </h4>
            <Container fluid>
                <Row>
                    <Col >
                        {/* FUCKING IIMAGESS */}
                    </Col>
                    <Col md={3}>
                        <ul className="social-networks" style={{ listStyleType: 'none' }}>
                            <li className="zoom">
                                <a href="http://www.vapiotlab.com/#/">
                                    <img src={vap} alt="facebook" className="imggg" />
                                </a>
                            </li>

                            {/* <br />
                            <br /> */}
                        </ul>
                        <p className="product1Info">
                            We are providing virtualized IoT learning
                            and development platform with varieties of
                            IoT products and sensors which enables
                            end-users to connect with the real hardware
                            virtually.
                        </p>
                    </Col>



                    <Col md={3}>
                        {/* FUCKING IIMAGESS */}
                    </Col>


                    <Col md={3}>
                        <ul style={{ listStyleType: 'none' }}   >
                            <li className="zoom">
                                <a href="https://surilli.io/">
                                    <img src={surilli} alt="linkedin" className="imgg" />
                                </a>
                            </li>
                        </ul>
                        {/* <br />
                        <br /> */}
                        <p className="product2Info">
                            {/* taiba */}
                            We are providing an IoT Platform
                            to help entrepreneurs and industries
                            create IoT Solutions easily to increase
                            productivity, enhance reliability and
                            maximize business values.
                        </p>

                    </Col>
                    <Col >
                        {/* FUCKING IIMAGESS */}
                    </Col>
                </Row>

            </Container>
            <br />
            <br />
        </div>
    );
}