// import React, { Component, useState, useEffect } from 'react';
import React, { Component } from 'react';
import './Services.css';
// import { Carousel } from 'react-responsive-carousel';
import web3 from '../assets/hardware.png';
import web2 from '../assets/mobile-app-development-2.svg';
import web1 from '../assets/web.svg';
// import web from '../assets/web.png';
// import mobile from '../assets/mobile.png';
// import data from '../assets/data.png';
// import Slider from "react-slick";
// import Carousel from 'nuka-carousel';
// import employee1 from '../assets/Grald.png';
// import employee2 from '../assets/taiba1.jpg';
// import employee3 from '../assets/jake.jpg';
// import employee4 from '../assets/paul.png';
// import employee5 from '../assets/veronica.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';







class Services extends Component {
    render() {


        return (


            <div id="servicesId">

                {/* <br /> */}
                <br />
                <br />
                <br />
                {/* <br/> */}
                <h4 className="relationHeadServices">
                    {/* We take care of all the technology so you can focus on what you do best */}
                    WHAT ARE OUR OFFERINGS

               </h4>
                <br />
                <br />
                <Container>
                    <Row>
                        <Col>
                            <div className="service1" style={{}}>
                                <img src={web1} className="servicesImages" alt="silverBackBanner" />
                                <br /><br /><br />
                                <h3 className="servicesHeaders">WEB</h3>
                                <p className="servicesParas">
                                    <h5 className="serviceData">We offer web app development and ongoing support, including:</h5>
                                    <ul className="servicesList">
                                        <li>Customized web development</li>
                                        <li>Performance monitoring & optimization</li>
                                        <li>Cloud-based deployment</li>
                                        <li>API development</li>
                                        <li>Search engine optimization (SEO)</li>
                                        <li>E-commerce solutions</li>
                                        <li>LMS solution</li>
                                    </ul>

                                </p>
                            </div>

                        </Col>

                        <Col>
                            <div className="service2">
                                <img src={web2} className="servicesImages" alt="silverBackBanner" />
                                <br /><br /><br />
                                <h3 className="servicesHeaders" style={{ paddingTop: '0px' }}>MOBILE</h3>
                                <p className="servicesParas">
                                    <h5 className="serviceData">We offer a full cycle of application design, management
                                    services and integration.
                                    </h5>
                                    <ul className="servicesList">
                                        <li>Custom iOS and Android apps development</li>
                                        <li>Native and cross-platform solutions</li>
                                        <li>UI/UX design</li>
                                        <li>Automated QA and testing</li>
                                        <li>Maintenance and post-warranty support</li>
                                        {/* <li>E-commerce solutions</li>
                                        <li>LMS solution</li> */}
                                    </ul>
                                </p>

                            </div>

                        </Col>

                        <Col>
                            <div className="service3">
                                <img src={web3} className="servicesImages" alt="silverBackBanner" />
                                <br /><br /><br />
                                <h3 className="servicesHeaders" style={{ paddingTop: '0px' }}>HARDWARE</h3>
                                <p className="servicesParas">
                                    <h5 className="serviceData">We provide scalable, efficient, comprehensive
                                        and cost-effective customized hardware solutions
                                        that are best suited for our customers needs. Our
                                        expertise includes:
                                    </h5>
                                    <ul className="servicesList">
                                        <li>PCB Designing</li>
                                        <li>Integration of hardware with connectivity modules</li>
                                        <li> configuration of PCs with a ITS image
                                        </li>

                                    </ul>
                                </p>

                            </div>
                        </Col>
                    </Row>


                </Container>

               
                <br />
                <br />
                <br />
                <br />


                {/* <div className="container">
                    <h4 className="relationHead">Great software needs great relationships</h4>
                    <p className="relationPara">Beyond our skilled and expertise, it is our customers and our relationship
                        with them that sets us apart.
                    </p>

                </div>
 */}

                {/* <div className="employees"> */}


                {/* <div>
                        <h2> Responsive </h2>
                        <Slider {...settings}>
                            <div>
                                <h3>1</h3>
                            </div>
                            <div>
                                <h3>2</h3>
                            </div>
                            <div>
                                <h3>3</h3>
                            </div>
                            <div>
                                <h3>4</h3>
                            </div>
                            <div>
                                <h3>5</h3>
                            </div>
                            <div>
                                <h3>6</h3>
                            </div>
                            <div>
                                <h3>7</h3>
                            </div>
                            <div>
                                <h3>8</h3>
                            </div>
                        </Slider>
                    </div> */}




                {/* <Carousel>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <img src={employee1} alt="employee1" className="employeesSection" />
                            </div>
                            <div>
                                <div className="desc">
                                    <h4 className="employeeName">Gerald Devlin</h4>
                                    <p className="subtitle">CEO &amp; Founder, Lendaid Inc.</p>
                                    <hr className="hr_color" />
                                    <br />
                                    <div className="blockquote">
                                        <blockquote>
                                            I certainly attribute the robustness of
                                            the search engine to them and I’m glad
                                            to have found someone with their resources.
                                            They’ve worked with large companies, and
                                            their name gives clout to my business.
                                            They delivered a high-end product and
                                            feedback has been great.
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div style={{ display: 'flex' }}>
                            <div>
                                <img src={employee2} alt="employee2" className="employeesSection" />
                            </div>
                            <div>
                                <div className="desc">
                                    <h4 className="employeeName">Taiba Naseem</h4>
                                    <p className="subtitle">Front-end Developer, World Bank</p>
                                    <hr className="hr_color" />
                                    <br />
                                    <div className="blockquote" >
                                        <blockquote>
                                            They delivered a high-quality product and
                                            their customer service was excellent. We’ve
                                            had other teams approach us, asking to use it
                                            for their own projects.
                                        </blockquote>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div style={{ display: 'flex' }}>
                            <div>
                                <img src={employee3} alt="employee3" className="employeesSection" />
                            </div>
                            <div>
                                <div className="desc">
                                    <h4 className="employeeName">Jake Peters</h4>
                                    <p className="subtitle"> CEO &amp; Co-Founder, PayPerks</p>
                                    <hr className="hr_color" />
                                    <br />
                                    <div className="blockquote">
                                        <blockquote>
                                            Arbisoft is an integral part of our team
                                            and we probably wouldn’t be here today
                                            without them. Some of their team has worked
                                            with us for 5–8 years and we’ve built a trusted
                                            business relationship. We share successes together.
                                        </blockquote>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div style={{ display: 'flex' }}>
                            <div>
                                <img src={employee4} alt="employee4" className="employeesSection" />
                            </div>
                            <div>
                                <div className="desc">
                                    <h4 className="employeeName">Paul English</h4>
                                    <p className="subtitle">Co-Founder, KAYAK</p>
                                    <hr className="hr_color" />
                                    <br />
                                    <div className="blockquote">
                                        <blockquote>I have managed remote teams
                                            now for over ten years, and our early
                                            work with Arbisoft is the best experience
                                            I've had for off site contractors.
                                        </blockquote>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div style={{ display: 'flex' }}>
                            <div>
                                <img src={employee5} alt="employee5" className="employeesSection" />
                            </div>
                            <div>
                                <div className="desc">
                                    <h4 className="employeeName">Alice Danon</h4>
                                    <p className="subtitle">Project Coordinator, World Bank</p>
                                    <hr className="hr_color" />
                                    <br />
                                    <div className="blockquote">
                                        <blockquote>They delivered a high-quality product
                                            and their customer service was excellent. We’ve
                                            had other teams approach us, asking to use it for
                                            their own projects.
                                        </blockquote>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </Carousel>

 */}


                {/* </div> */}


            </div>
        );
    }
}
export default Services;