import React from 'react';

import './slider.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from 'nuka-carousel';
import VideoLooper from 'react-video-looper'
// import surilli from '../assets/video.mp4';
// import surilli from '../assets/surillimaingif1.jpg';
// import surilli from '../assets/surillimaingif2.jpg';
import surilli from '../assets/surillimaingif2.jpg';


// import surilli1 from '../assets/1.jpg';


export default function Slider() {

    return (
        <div id="homeId">
            <Carousel>
                <div>
                    {/* <VideoLooper autplay loop mutes source={surilli} style={{ pointerEvents: 'none' }} /> */}

                    <img src={surilli} alt="silverBackBanner" className="silverBackBanner"/>
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src={surilli} alt="silverBackBanner" className="silverBackBanner"/>
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                    <img src={surilli} alt="silverBackBanner" className="silverBackBanner"/>
                    {/* <p className="legend">Legend 3</p> */}
                </div>
            </Carousel>
        </div>

    );
}




